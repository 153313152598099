import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "#F1EFE6",
          padding: theme.spacing(4, 8, 6),
          color: "#2c3e50"
      }
    }
});

function Disclaimer() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="row" justifyContent="center">
                <Typography variant='body2'><strong>© 2024 LEAD GEN AI. All rights reserved.</strong></Typography>
            </Grid>
        </div>    
    );
  }
  
export default Disclaimer;