import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../sections/main_page/Navbar';
import Info from '../sections/main_page/Info';
import TrustedBy from '../sections/main_page/TrustedBy';
import HowItWorks from '../sections/main_page/HowItWorks';
import Disclaimer from '../sections/Disclaimer';

// Attributions:
// <a href="https://www.freepik.com/icon/rating_12397755">Icon by cah nggunung</a>
// <a href="https://www.freepik.com/icon/integrations_11681327#fromView=search&page=3&position=82&uuid=e5cda9cd-568d-4a7a-a80a-ce91afe0e337">Icon by Cap Cool</a>
// <a href="https://www.freepik.com/icon/teamwork_2981990#fromView=search&page=1&position=58&uuid=60d624bd-a325-4c50-b433-10108185e4d6">Icon by itim2101</a>


const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Main() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <Navbar/>
            <Info/>
            {/* <TrustedBy/> */}
            <HowItWorks/>
            <Disclaimer/>
          </div>    
      );
    }
    
  export default Main;