import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';


import Logo from "../../images/logos/LGAI.png";


const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            background: "#EEF1E7",
            boxShadow: "None",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10)
        },

        logo: {
          width: '100%',
          display: 'block',
          margin: '0 auto',
        },

        button1: {
          background: 'linear-gradient(to bottom left, #EF4765, #FF9A5A)',
          border: 0,
          borderRadius: 12,
          color: '#FFFFFF',
          // cursor: 'pointer',
          fontSize: "1rem",
          fontWeight: 700,
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          transition: 'box-shadow .2s ease-in-out',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          '&:focus, &:hover': {
            boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
          },
        },

        button2: {
          background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
          border: 0,
          borderRadius: 12,
          color: '#FFFFFF',
          // cursor: 'pointer',
          fontSize: "1rem",
          fontWeight: 700,
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          transition: 'box-shadow .2s ease-in-out',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          '&:focus, &:hover': {
            boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
          },
        }

    }
});

function Navbar() {
    const classes = useStyles();

    return (
        <div >
          <AppBar className={ classes.appBar } position="relative">
            <Toolbar>
              <Grid container alignItems="center" justifyContent="space-between">
                {/* Logo on the very left */}
                <Grid item md={3} justifyContent="center" alignItems='center' alignContent='center'>
                  <img src={Logo} alt="Logo" className={classes.logo} />
                </Grid>

                <Grid container item md={3}>
                  <Grid container spacing={3} alignItems='center' justifyContent="center" >
                    {/* <Grid item >
                      <Button className={classes.button1} href='' target='_blank'>SIGN UP</Button>
                    </Grid> */}
                    <Grid item>
                      <Button className={classes.button2} href='login'>GET STARTED</Button>
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
            </Toolbar>
          </AppBar>
        </div>    
    );
  }
  
export default Navbar;

