import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useAuthHeader } from 'react-auth-kit';
import axios from "axios";


import Logo from "../../images/logos/LGAI.png";


const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            background: "#EEF1E7",
            boxShadow: "None",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10)
        },

        logo: {
          maxWidth: '100%',
          display: 'block',
          margin: '0 auto',
        },

        button1: {
          background: 'linear-gradient(to bottom left, #EF4765, #FF9A5A)',
          border: 0,
          borderRadius: 12,
          color: '#FFFFFF',
          // cursor: 'pointer',
          fontSize: "1rem",
          fontWeight: 700,
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          transition: 'box-shadow .2s ease-in-out',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          '&:focus, &:hover': {
            boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
          },
        },

        button2: {
          background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
          border: 0,
          borderRadius: 12,
          color: '#FFFFFF',
          // cursor: 'pointer',
          fontSize: "1rem",
          fontWeight: 700,
          padding: '10px 20px',
          textAlign: 'center',
          textDecoration: 'none',
          transition: 'box-shadow .2s ease-in-out',
          userSelect: 'none',
          whiteSpace: 'nowrap',
          '&:focus, &:hover': {
            boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
          },
        },

        blueText: {
          color: "#2c3e50"
        },

    }
});

function Navbar() {
    const classes = useStyles();

    const [loggedInAs, setLoggedInAs] = useState("");
    const authHeader = useAuthHeader();

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/realtor_details`, {
            // withCredentials: true, // Include credentials (cookies) in requests
            headers: {
              'Authorization': authHeader()
            },
          });

          setLoggedInAs(response.data.logged_in_as);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();

    }, [authHeader]);

    return (
        <div >
          <AppBar className={ classes.appBar } position="relative">
            <Toolbar>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item  md={4}>
                  <img src={Logo} alt="Logo" className={classes.logo} />
                </Grid>

                <Grid item justifyContent='center'>
                  <Typography className={ classes.blueText } variant='h3'><strong>👋 Hi {loggedInAs.toUpperCase()}</strong></Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>    
    );
  }
  
export default Navbar;

