import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import LoginSection from '../sections/login_page/Login';

const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Login() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <LoginSection/>
          </div>    
      );
    }
    
  export default Login;