import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Carousel from 'react-material-ui-carousel';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Realtor1 from "../../images/logos/youssef.jpg";

import century from "../../images/agencies/century.png";
import lepage from "../../images/agencies/lepage.svg.png";
import remax from "../../images/agencies/remax.png";

const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#e6f4fc",
        padding: theme.spacing(4, 8, 6),
      },

      blackText: {
        color: "#1a1a1a"
      },

      orangeText: {
        color: "#F04C24"
      },

      button2: {
        background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
        border: 0,
        borderRadius: 12,
        color: '#FFFFFF',
        // cursor: 'pointer',
        fontSize: "1.4rem",
        fontWeight: 700,
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'box-shadow .2s ease-in-out',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&:focus, &:hover': {
          boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
        },
      },

      carousal: {
        // display: 'flex',
        // margin: 'auto',
        backgroundColor: "#e6f4fc",
        padding: theme.spacing(3),
        maxWidth: "60%"
        // width: '80%', // Adjust width as needed
      },

      card: {
        // display: 'flex',
        // margin: 'auto',
        backgroundColor: "#F1EFE6",
        padding: theme.spacing(3),
        borderRadius: "1rem"      
        // width: '80%', // Adjust width as needed
      },

      avatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
      },

      content: {
        flexGrow: 1,
      },

      topMargin: {
        marginTop: "3rem"
      },

      agencyImage: {
        minWidth: "7rem",
        minHeight: "3rem",
        maxWidth: "7rem",
      }
      
    }
});


function TrustedBy() {
    const classes = useStyles();

    const items = [
      {
        name: <div className={ classes.blackText }><Typography align='center' variant='h6'><strong>Youssef N.</strong></Typography><Typography align='center' variant='body2'><i>REMAX - OAKVILLE</i></Typography></div>,
        image: Realtor1,
        text: "Lead Gen has been a game-changer for my real estate business, making it incredibly easy to generate high-quality leads. The platform's intuitive interface and powerful targeting tools have significantly increased my client base. With Lead Gen, I've seen a noticeable boost in my sales and overall efficiency, making it an indispensable tool for any real estate professional.",
      },
      {
        name: <div className={ classes.blackText }><Typography align='center' variant='h6'><strong>Youssef N.</strong></Typography><Typography align='center' variant='body2'><i>21 CENTURY - TORONTO</i></Typography></div>,
        image: Realtor1,
        text: "Lead Gen has been a game-changer for my real estate business, making it incredibly easy to generate high-quality leads. The platform's intuitive interface and powerful targeting tools have significantly increased my client base. With Lead Gen, I've seen a noticeable boost in my sales and overall efficiency, making it an indispensable tool for any real estate professional.",
      },
      {
        name: <div className={ classes.blackText }><Typography align='center' variant='h6'><strong>Youssef N.</strong></Typography><Typography align='center' variant='body2'><i>ELITE REALTORS - VANCOUVER</i></Typography></div>,
        image: Realtor1,
        text: "Lead Gen has been a game-changer for my real estate business, making it incredibly easy to generate high-quality leads. The platform's intuitive interface and powerful targeting tools have significantly increased my client base. With Lead Gen, I've seen a noticeable boost in my sales and overall efficiency, making it an indispensable tool for any real estate professional.",
      },
    ];
  

    return (
        <div className={ classes.container }>
          <Grid container spacing={1} direction="column" alignItems='center'>
            <Grid container item >
              <Grid container direction='column' justifyContent="center" alignItems='center' alignContent='center'>
                  <Grid item md={8}>
                      <Typography className={ classes.blackText } variant='h3' align='center'>
                          <strong>TRUSTED BY TOP REALTORS</strong> 
                      </Typography>
                  </Grid>
              </Grid>
            </Grid>

            <Carousel indicatorContainerProps={{ // Set indicatorContainerProps to null
                style: {
                  display: 'none' // Hide the pagination dots
                }
              }}
              animation='fade'
              timeout={{ enter: 1000, exit: 1000, appear: 3000 }}
              className={ classes.carousal }
              >
              {items.map((item, index) => (
                <Card className={ classes.card } elevation={8}>
                  <Grid container direction='row' spacing={4}>
                    <Grid container item md={4}>
                      <Grid container direction='column' alignItems='center'>
                        <Grid item>
                          <Avatar src={item.image} className={classes.avatar} />
                        </Grid>
                        <Grid item>
                          {item.name}
                        </Grid>
                      </Grid>
                    </Grid>
                    
                    <Grid item md={8} alignContent='center'>
                      <Typography className={ classes.blackText } variant="subtitle1"><strong><i>{item.text}</i></strong>  </Typography>
                    </Grid>
                  </Grid>

                </Card>
              ))}
            </Carousel>

              <Grid container item className={classes.topMargin} direction="column" alignItems="center">
                <Grid item>
                  <Typography className={classes.blackText} variant="h5" align="center">
                    <strong>SUPPORTED BY PREMIER REAL ESTATE AGENCIES</strong>
                  </Typography>
              </Grid>

              <Grid container item justifyContent="center" spacing={4} style={{ marginTop: "0.5rem" }}>
                <Grid item>
                  <Box
                    display="flex"
                    className={classes.agencyImage}
                    component="img"
                    alt="logo"
                    src={century}
                    alignSelf="center"
                  />
                </Grid>

                <Grid item>
                  <Box
                    display="flex"
                    className={classes.agencyImage}
                    component="img"
                    alt="logo"
                    src={lepage}
                    alignSelf="center"
                  />
                </Grid>

                <Grid item>
                  <Box
                    display="flex"
                    className={classes.agencyImage}
                    component="img"
                    alt="logo"
                    src={remax}
                    alignSelf="center"
                  />
                </Grid>

                <Grid item>
                  <Box
                    display="flex"
                    className={classes.agencyImage}
                    component="img"
                    alt="logo"
                    src={century}
                    alignSelf="center"
                  />
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>    
    );
  }
  
export default TrustedBy;

