import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../sections/preferences_page/Navbar';
import SelectPreferences from '../sections/preferences_page/SelectPreferences';
import Disclaimer from '../sections/Disclaimer';


const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Preferneces() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <Navbar/>
            <SelectPreferences/>
            <Disclaimer/>
          </div>    
      );
    }
    
  export default Preferneces;