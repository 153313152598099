import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';

import Logo from "../../images/logos/LGAI.png";


const useStyles = makeStyles((theme) => ({
  divContainer: {
    backgroundColor: "#EEF1E7",
    padding: theme.spacing(4, 8, 6),
    height: '100vh',
  },

  container: {
    backgroundColor: "#F1EFE6",
    padding: theme.spacing(4, 8, 6),
    borderRadius: 12,
    maxWidth: "25rem",
    margin: 'auto',
    marginTop: theme.spacing(15),
  },

  orangeText: {
    color: "#F04C24",
  },

  button2: {
    background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
    border: 0,
    borderRadius: 12,
    color: '#FFFFFF',
    fontSize: "1.4rem",
    fontWeight: 700,
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'box-shadow .2s ease-in-out',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    '&:focus, &:hover': {
      boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
    },
  },

  textField: {
    minWidth: "18rem",
    marginBottom: theme.spacing(2)
  },

  logo: {
    width: '100%',
    display: 'block',
    margin: '0 auto',
  },

}));

function Login() {
  const classes = useStyles();

  const signIn = useSignIn();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        values
      );

      const signInResult = signIn({
        token: response.data.access_token,
        expiresIn: 3600*2,
        tokenType: "Bearer",
        // authState: { email: values.email },
      });

      if (signInResult) {
        navigate('/preferences'); // Navigate to the next page
      } else {
        setErrorMessage("Invalid email or access code. Please try again.");
      }

    } catch (err) {
      setErrorMessage("Invalid email or access code. Please try again.");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
    },
    onSubmit,
  });


  return (
    <div className={classes.divContainer}>
      <Card className={classes.container}>
        <Grid container spacing={1} direction="column" alignItems="center">
          <Grid item alignItems='center' alignContent='center'>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid item>
              <TextField
                className={classes.textField}
                label="Email"
                variant="outlined"
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.textField}
                label="Access Code"
                variant="outlined"
                fullWidth
                id="code"
                name="code"
                value={formik.values.code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
            </Grid>
            {errorMessage && (
              <Grid item className={classes.textField}>
                <Typography color="error" variant="body2">
                 <strong>{errorMessage}</strong>
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                className={classes.button2}
                type="submit"
                fullWidth
              >
                Login
              </Button>
            </Grid>
          </form>
        </Grid>
      </Card>
    </div>
  );
}

export default Login;
