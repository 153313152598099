import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import { AuthProvider } from 'react-auth-kit';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider 
          authType={"cookie"}
          authName={"_auth"}
          cookieDomain={window.location.hostname}
          cookieSecure={false}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </AuthProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
