import Main from "./pages/Main.jsx";
import Preferences from "./pages/Preferences.jsx";
import { RequireAuth } from 'react-auth-kit';
import Login from "./pages/Login.jsx";
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Main/>} />
      <Route path="/login" element={<Login/>} />
      <Route
          path="/preferences"
          element={
            // <RequireAuth loginPath="/login">
              <Preferences/>
            // </RequireAuth> 
          }
        ></Route>
    </Routes>
  );
}

export default App;
