import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Carousel from 'react-material-ui-carousel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import PersonIcon from '@material-ui/icons/Person';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import HotelRoundedIcon from '@material-ui/icons/HotelRounded';
import BathtubRoundedIcon from '@material-ui/icons/BathtubRounded';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocationCityRoundedIcon from '@material-ui/icons/LocationCityRounded';

const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#FFFDF9",
        padding: theme.spacing(4, 8, 6),
      },

      blackText: {
        color: "#1a1a1a"
      },

      orangeText: {
        color: "#F04C24"
      },

      greyText: {
        color: "#a6a6a6"
      },

      blueText: {
        color: "#2c3e50"
      },
      
      button2: {
        background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
        border: 0,
        borderRadius: 12,
        color: '#FFFFFF',
        // cursor: 'pointer',
        fontSize: "1.4rem",
        fontWeight: 700,
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'box-shadow .2s ease-in-out',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&:focus, &:hover': {
          boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
        },
      },

      button: {
        fontSize: "1.2rem",
        padding: "0.5rem 2rem",
        borderRadius: '8rem', // Oval shape
        // padding: '10px 30px', // Adjust padding as needed
        color: '#F04C24', // Text color
        border: '2px solid #F04C24', // Border color
        backgroundColor: 'rgba(240, 76, 36, 0.07)', // Light orange color on hover
        transition: 'background-color 0.3s ease, color 0.3s ease',
        '&:hover': {
          backgroundColor: 'rgba(240, 76, 36, 0.18)', // Light orange color on hover
        },
        '&.selected': {
          color: '#FFFDF9', // Text color
          backgroundColor: '#F04C24', // Light orange color on hover
          '&:hover': {
            backgroundColor: 'rgba(240, 76, 36, 0.91)'
          },
        },
      },

      selectedButton: {
        fontSize: "1.2rem",
        padding: "0.5rem 2rem",
        borderRadius: '8rem', // Oval shape
        // padding: '10px 30px', // Adjust padding as needed
        color: '#FFFDF9', // Text color
        border: '2px solid #F04C24', // Border color
        backgroundColor: '#F04C24', // Light orange color on hover
        transition: 'background-color 0.3s ease, color 0.3s ease',
        '&:hover': {
          backgroundColor: 'rgba(240, 76, 36, 0.91)'
        },
      }, 
      
      card: {
        backgroundColor: "#f5ede4",
        padding: theme.spacing(2),
        borderRadius: "1rem",
        width: "100%"
      },

      icon: {
        fontSize: "2.8rem",
        color: "#2c3e50"
      }
    }
});


function SelectPreferences() {
    const classes = useStyles();

    const [selectedButtons, setSelectedButtons] = useState({
      buyer: false,
      renter: false,
      leaser: false,
      seller: false,

      singleFamily: false,
      condo: false,
      townhouses: false,
      privateRoom: false,
      duplexes: false,
      bungalow: false,
      mansions: false,

      city: false,
      suburbs: false,
      rural: false,
      proximityToSchools: false,
      proximityToPublicTransit: false,
      waterfrontOrBeachfront: false,

      studio: false,
      oneRoom: false,
      twoRoom: false,
      threeRoom: false,
      fourPlusRoom: false,

      oneBath: false,
      oneHalfBath: false,
      twoBath: false,
      twoHalfBath: false,
      threePlusBath: false,
    });
    
    const handleButtonClick = (buttonId) => {
      setSelectedButtons((prevState) => ({
        ...prevState,
        [buttonId]: !prevState[buttonId],
      }));
    };

    return (
        <div className={ classes.container }>
          <Grid container spacing={7} direction="column" alignItems='center'>

            <Grid item>
              <Typography className={ classes.orangeText } variant='h3' align='center'>
                  <strong>SELECT YOUR LEAD PREFERENCES</strong> 
              </Typography>
              <Typography className={ classes.blueText } variant='h6' align='center'>
                  <strong>THESE PREFERENCES MATCH YOU WITH THE TYPE OF LEADS YOU ARE LOOKING FOR.</strong> 
              </Typography>
              
            </Grid>


            {/* SECTION 1 */}
            <Grid container item>
              <Typography className={ classes.blueText } variant='subtitle1' align='center'>
                  Select as many or as little preferences as you would like. Change them at any time.
              </Typography>

              <Card className={classes.card} elevation={1}>

                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <PersonIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>LEAD PROFILE</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['buyer'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('buyer')}
                        >
                          <strong>BUYER</strong>
                        </Button>
                      </Grid>


                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['seller'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('seller')}
                        >
                          <strong>SELLER</strong>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>

              </Card>
            </Grid>

            {/* SECTION 2 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <HomeRoundedIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>HOME TYPE</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['singleFamily'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('singleFamily')}
                        >
                          <strong>DETACHED</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['condo'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('condo')}
                        >
                          <strong>SEMI-DETACHED</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['townhouses'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('townhouses')}
                        >
                          <strong>FREE-HOLD TOWNHOUSE</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['privateRoom'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('privateRoom')}
                        >
                          <strong>CONDO TOWNHOUSE</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['duplexes'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('duplexes')}
                        >
                          <strong>CONDO APARTMENT</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['bungalow'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('bungalow')}
                        >
                          <strong>MULTIPLEX</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['mansions'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('mansions')}
                        >
                          <strong>VACANT LAND</strong>
                        </Button>
                      </Grid>


                    </Grid>
                  </Grid>
                  
                </Grid>

              </Card>
              
            </Grid>

            {/* SECTION 3 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LocationOnRoundedIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>LOCATION PREFERENCES</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['city'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('city')}
                        >
                          <strong>City</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['suburbs'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('suburbs')}
                        >
                          <strong>Suburbs</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['rural'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('rural')}
                        >
                          <strong>Rural</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['proximityToSchools'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('proximityToSchools')}
                        >
                          <strong>Proximity to Schools</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['proximityToPublicTransit'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('proximityToPublicTransit')}
                        >
                          <strong>Proximity to Public Transport</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['waterfrontOrBeachfront'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('waterfrontOrBeachfront')}
                        >
                          <strong>Waterfront or Beachfront</strong>
                        </Button>
                      </Grid>


                    </Grid>
                  </Grid>
                  
                </Grid>
              </Card>
              
            </Grid>

            {/* SECTION 4 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <HotelRoundedIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>NUMBER OF BEDROOMS</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['studio'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('studio')}
                        >
                          <strong>Studio</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneRoom'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('oneRoom')}
                        >
                          <strong>1</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoRoom'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('twoRoom')}
                        >
                          <strong>2</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threeRoom'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('threeRoom')}
                        >
                          <strong>3</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['fourPlusRoom'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('fourPlusRoom')}
                        >
                          <strong>4+</strong>
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                  
                </Grid>
              </Card>
              
            </Grid>

            {/* SECTION 5 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <BathtubRoundedIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>NUMBER OF BATHROOMS</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneBath'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('oneBath')}
                        >
                          <strong>1</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneHalfBath'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('oneHalfBath')}
                        >
                          <strong>1.5</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoBath'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('twoBath')}
                        >
                          <strong>2</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoHalfBath'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('twoHalfBath')}
                        >
                          <strong>2.5</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>3+</strong>
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                  
                </Grid>
              </Card>
            </Grid>

            {/* SECTION 6 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <MonetizationOnIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>PRICE</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('oneBath')}
                        >
                          <strong>0-250K</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneHalfBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('oneHalfBath')}
                        >
                          <strong>250-500K</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('twoBath')}
                        >
                          <strong>500-750K</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoHalfBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('twoHalfBath')}
                        >
                          <strong>750K-1M</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>1M+</strong>
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                  
                </Grid>
              </Card>
            </Grid>

            {/* SECTION 7 */}
            <Grid container item >
              <Card className={classes.card}>
                <Grid container direction='column' spacing={3}>
                  <Grid container item>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <LocationCityRoundedIcon className={classes.icon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.blueText} variant='h4'>
                          <strong>CITY/TOWN</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item>
                    <Grid container direction='row' spacing={4}>
                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('oneBath')}
                        >
                          <strong>TORONTO</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['oneHalfBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('oneHalfBath')}
                        >
                          <strong>MISSISSAUGA</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('twoBath')}
                        >
                          <strong>scarborough</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['twoHalfBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('twoHalfBath')}
                        >
                          <strong>VAUGHAN</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>OAKVILLE</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>HAMILTON</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>BRAMPTON</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>MILTON</strong>
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button 
                          size="large"
                          variant="outlined"
                          className={`${classes.button} ${
                            selectedButtons['threePlusBath'] ? classes.selectedButton : ''
                          }`}
                          // onClick={() => handleButtonClick('threePlusBath')}
                        >
                          <strong>BURLINGTON</strong>
                        </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                  
                </Grid>
              </Card>
            </Grid>

            <Grid container item>
              <Grid container direction="column" spacing={5} alignItems='center'>
                <Grid item>
                  <Button 
                    size="large"
                    variant="outlined"
                    className={`${classes.button} ${
                      selectedButtons['buyer'] ? classes.selectedButton : ''
                    }`}
                    // onClick={() => handleButtonClick('buyer')}
                  >
                    <strong>SUBMIT</strong>
                  </Button>
                </Grid>

                <Grid item>
                  <Button 
                    size="large"
                    variant="outlined"
                    className={`${classes.button} ${
                      selectedButtons['buyer'] ? classes.selectedButton : ''
                    }`}
                    // onClick={() => handleButtonClick('buyer')}
                  >
                    <strong>EXPORT TODAYS LEADS</strong>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>    
    );
  }
  
export default SelectPreferences;

