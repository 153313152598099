import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


import leads from "../../images/logos/LGAI Leads-4.gif";


const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#F1EFE6",
        padding: theme.spacing(4, 8, 6),
      },

      orangeText: {
        color: "#F04C24"
      },

      logo: {
        width: "26rem",
        maxWidth: '100%',
        maxHeight: 'auto',
      },

      button2: {
        background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
        border: 0,
        borderRadius: 12,
        color: '#FFFFFF',
        // cursor: 'pointer',
        fontSize: "1.4rem",
        fontWeight: 700,
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'box-shadow .2s ease-in-out',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&:focus, &:hover': {
          boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
        },
      }
      
    }
});

function Info() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
          <Grid container spacing={1} direction="column" alignItems='center'>
            <Grid container item >
              <Grid container direction='column' justifyContent="center" alignItems='center' alignContent='center'>
                  <Grid item md={8}>
                      <Typography variant='h3' align='center'>
                          <strong><Typography className={ classes.orangeText } variant='h3' display='inline'><strong>AI-POWERED</strong></Typography> LEAD GENERATION FOR REAL ESTATE</strong> 
                      </Typography>
                  </Grid>
              </Grid>
            </Grid>

            <Grid xs={12} item justifyContent="center" alignItems='center' alignContent='center'>
              <img src={leads} alt="Logo" className={ classes.logo }/>
            </Grid>

            <Grid item>
              <Button className={classes.button2} href='login'>GET MY LEADS NOW</Button>
            </Grid>


          </Grid>
        </div>    
    );
  }
  
export default Info;

