import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Carousel from 'react-material-ui-carousel';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import ai from "../../images/icons/ai.png";
import leads from "../../images/icons/leads.png";
import select from "../../images/icons/select.png";


const useStyles = makeStyles((theme) => {
    return {
      container: {
        backgroundColor: "#FFFDF9",
        padding: theme.spacing(4, 8, 6),
      },

      blackText: {
        color: "#1a1a1a"
      },

      orangeText: {
        color: "#F04C24"
      },

      blueText: {
        color: "#2c3e50"
      },

      button2: {
        background: 'linear-gradient(to bottom right, #EF4765, #FF9A5A)',
        border: 0,
        borderRadius: 12,
        color: '#FFFFFF',
        // cursor: 'pointer',
        fontSize: "1.4rem",
        fontWeight: 700,
        padding: '10px 20px',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'box-shadow .2s ease-in-out',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        '&:focus, &:hover': {
          boxShadow: '0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5)',
        },
      },

      carousal: {
        // display: 'flex',
        // margin: 'auto',
        backgroundColor: "#e6f4fc",
        padding: theme.spacing(3),
        maxWidth: "60%"
        // width: '80%', // Adjust width as needed
      },

      card: {
        // display: 'flex',
        // margin: 'auto',
        backgroundColor: "#F1EFE6",
        padding: theme.spacing(1.5),
        borderRadius: "1rem"      
        // width: '80%', // Adjust width as needed
      },

      avatar: {
        minWidth: "7rem",
        minHeight: "7rem",
      },

      content: {
        flexGrow: 1,
      },

      topMargin: {
        marginTop: "3rem"
      },

      topMarginText: {
        marginTop: "1.5rem"
      },
      
    }
});


function HowItWorks() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
          <Grid container spacing={7} direction="column" alignItems='center'>
            
            <Grid item>
              <Typography className={ classes.blackText } variant='h3' align='center'>
                  <strong><Typography className={ classes.orangeText } variant='h3'><strong>GET HIGH-QUALITY LEADS</strong></Typography> AS SOON AS TOMORROW</strong> 
              </Typography>
            </Grid>
             

            <Grid container item >
              <Grid container justifyContent='space-evenly' spacing={3}>
                <Grid item xs={9} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container direction="column">
                        <Avatar src={select} className={classes.avatar} variant='square'/>
                        <Typography variant="h5" className={`${classes.orangeText} ${classes.topMarginText}`}>
                          <strong>1. SELECT YOUR PREFERENCES</strong>
                        </Typography>
                        <Typography variant="h6" className={`${classes.blueText} ${classes.topMarginText}`}>
                        Tailor your lead generation strategy by specifying fully customizable lead profiles. Our AI-powered platform allows you to define your ideal client profiles and match them with potential leads who are looking for homes that meet their preferences. Efficiently target a wide range of clients and streamline your lead acquisition process with our innovative platform.                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={9} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container direction="column">
                        <Avatar src={ai} className={classes.avatar} variant='square'/>
                        <Typography variant="h5" className={`${classes.orangeText} ${classes.topMarginText}`}>
                          <strong>2. LET AI DO ITS MAGIC</strong>
                        </Typography>
                        <Typography variant="h6" className={`${classes.blueText} ${classes.topMarginText}`}>
                        Sit back and let our advanced AI-powered system work its magic to bring you the best leads. Our intelligent algorithms analyze vast amounts of data to match you with potential clients who are looking for homes that align with your preferences. Enjoy the efficiency of automated lead generation, freeing you to focus on what you do best—building relationships and closing deals.
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={9} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Grid container direction="column">
                        <Avatar src={leads} className={classes.avatar} variant='square'/>
                        <Typography variant="h5" className={`${classes.orangeText} ${classes.topMarginText}`}>
                          <strong>3. GET YOUR LEADS</strong>
                        </Typography>
                        <Typography variant="h6" className={`${classes.blueText} ${classes.topMarginText}`}>
                        Your personalized leads are just a click away! Get daily access to your curated list of potential leads who are active in the real estate market. Dive into detailed profiles and contact information to start connecting with your prospects immediately. Don't miss out on valuable opportunities—secure your leads now and watch your real estate business thrive!
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            <Grid item >
              <Button className={classes.button2} href='login'>GET MY LEADS NOW</Button>
            </Grid>

          </Grid>
        </div>    
    );
  }
  
export default HowItWorks;

